<template>
  <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WGCC55QT"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>

  <div style="position: fixed; right: 15px; bottom: 15px; z-index: 999">
    <i @click="openWp()" class="fa fa-whatsapp" style="font-size:48px;color:white; cursor: pointer; background-color: #25D366; padding: 5px; border-radius: 5px"></i>
  </div>
  <div class="wrapper">
    <!-- TEST HEADER: <div style="background-color: black; width: 100%; position: fixed; height: 40px;"></div> -->
    <div class="about-content-display">
      <div class="about-content-firt-block">
        <h3 class="about-content-first-block-title">Licenciada Daiana Mariel Gabriele</h3>
        <p class="about-content-firt-block-desc">
          Psicóloga. <br />Terapia cognitivo conductual. Mindfulness.
        </p>
        <div style="display: flex; justify-content: space-around; align-items: center;">
          <a @click="scrollToAbout" class="about-content-firt-block-button"><span style="color: #ffffff;">Más información</span></a>
          <i @click="openWp()" class="fa fa-whatsapp" style="font-size:48px;color:#25D366; cursor: pointer"></i>
          <a href="mailto:daianamarielgabriele@hotmail.com" class="fa fa-envelope" style="font-size:36px; color: #d7afaf;"></a>
        </div>
      </div>
      <div>
        <div class="about-content-second-block">
          <div class="second-block-image-block second-block-image-block_one"></div>
          <div class="second-block-image-block second-block-image-block_two"></div>
          <!-- <div class="second-block-main-image"></div> -->
          <img class="second-block-main-image" src="../assets/psi-logo.jpeg">
        </div>
      </div>
    </div>

    <div class="treatments-block">
      <!-- <h1>Servicios:</h1> -->

      <!-- <div class="grid">
      <div class="treatments-block-cards-wrapper">
        <div class="treatments-block-card"></div>
        <div class="treatments-block-card"></div>
        <div class="treatments-block-card"></div>
        <div class="treatments-block-card"></div>
        <div class="treatments-block-card"></div>
        <div class="treatments-block-card"></div>
      </div> -->

      <div class="cards-wrapper">
        <div class="cards">
          <div class="card">
            <h5 class="card-title">Terapia para pacientes que residen en el exterior</h5>
            <p class="card-description">En las sesiones psicológicas para pacientes que residen en el exterior y enfrentan problematicas relacionadas a las emociones que generan la separación del entorno cultural y social, lo cual suele llevar a explorar sentimientos de pérdida, soledad o identidad cultural. Se trabaja en estrategias para adaptarse a un nuevo contexto, fortalecer la resiliencia emocional y establecer conexiones significativas en el país de acogida. La terapia proporciona un espacio seguro para procesar las complejidades del desarraigo y desarrollar herramientas para una adaptación saludable.</p>
          </div>
          <div class="card">
            <h5 class="card-title">Sesiones de terapia individual online</h5>
            <p class="card-description">En una sesión de terapia cognitivo-conductual (TCC) virtual, los participantes se conectan a través de videollamada de WhatssApp. Durante la sesión, la terapeuta guía al paciente para identificar pensamientos disfuncionales y patrones de comportamiento negativos. Se utilizan herramientas específicas de la TCC, adaptadas al entorno virtual, para abordar y cambiar estos patrones. La terapia se enfoca en objetivos concretos, ofreciendo estrategias prácticas para superar desafíos emocionales y conductuales.</p>
          </div>
          <div class="card">
            <h5 class="card-title"> Sesiones de Mindfulness individual</h5>
            <p class="card-description">En una sesión de mindfulness, la terapeuta se enfoca en cultivar la atención plena al momento presente del consultante. Se guía a través de ejercicios de respiración consciente, exploración sensorial y atención focalizada. El objetivo es desarrollar la conciencia sin juicio hacia pensamientos y sensaciones. La práctica ayuda a reducir el estrés, mejorar la concentración y promover la aceptación de las circunstancias cuando estas no pueden ser cambiadas.</p>
          </div>
          <div class="card">
            <h5 class="card-title">Supervisión a psicólogos</h5>
            <p class="card-description">En este proceso, se revisan casos clínicos, se brinda retroalimentación sobre la práctica clínica, se discuten aspectos éticos y se ofrece apoyo emocional y profesional para el desarrollo y crecimiento del psicólogo supervisado. <br /> La supervisión ayuda a garantizar la calidad de la atención psicológica y promueve el desarrollo profesional continuo del psicólogo.</p>
          </div>
          <div class="card">
            <h5 class="card-title">Sesiones de psicoterapia individual presencial</h5>
            <p class="card-description">En una sesión de terapia cognitivo-conductual (TCC) presencial, se sigue un enfoque estructurado para abordar patrones de pensamiento y comportamiento disfuncionales. La terapeuta trabaja en colaboración con el paciente para identificar y modificar pensamientos negativos y conductas poco saludables. Se utilizan técnicas específicas, brindadas por la terapeuta y con base científica, como la reestructuración cognitiva y la exposición gradual, para promover cambios positivos. La terapia se centra en metas concretas y se proporciona un ambiente de apoyo para explorar y modificar patrones que contribuyan a problemas emocionales o conductuales.</p>
          </div>
          <div class="card">
            <h5 class="card-title">Cuadernillo con actividades terapéuticas</h5>
            <p class="card-description">Consultar al email.</p>
          </div>
        </div>
      </div>
    </div>

    <!-- ABOUT BIO -->
    <div class="bio-wrapper">
      <div id="myBioImage" class="bio-image-container">
        <!-- <div class="bio-image"></div> -->
        <img class="bio-image" src="../assets/profilePicture.jpeg">
      </div>
      <div class="bio-text">
        <p>
          Bienvenidos a mi espacio. Soy Daiana Mariel Gabriele, Lic. en Psicología y me he formado en cada oportunidad, comenzando mi práctica en el Hospital Nacinal Baldomero Sommer ad honorem, en mi consultorio particular desde el año 2015 y en instituciones escolares como Psicóloga Educacional. <br />

          Mi compromiso es proporcionar un espacio seguro y de apoyo para generar cambios saludables y una vida más plena para el consultante. <br /> Con una orientación hacia el cambio y el crecimiento personal, trabajaremos juntos para construir una base sólida para su bienestar emocional a largo plazo. <br />

          Mi formación es Licenciada en Psicología y he realizado posgrados en Psicopatología infanto Juvenil, Psicopatología en adultos, Terapia cognitivo conductual en niños, adolescentes y adultos y Mindfulness en Psicoterapia. Además, soy Profesora de nivel Superior en Psicología.
        </p>
      </div>
    </div>

    <!-- CONTACT US -->
    <div class="contact-us-wrapper">
      <div class="contact-us-wrapper-personal-info">
        <div>
          <h4 class="contact-us-personal-info-title">Información de Contacto</h4>
          <!-- <h5 class="contact-us-info-description">+54 911 6877 6345</h5> -->
          <a @click="openWp()" class="fa fa-whatsapp" style="font-size:48px;color:#25D366; cursor: pointer"></a>
        </div>
      </div>
      <div class="contact-us-wrapper-contact-us">
        <div class="contact-us-form">
          <h1 class="contact-us-title">Envie su consulta</h1>

          <input v-model="name" :class="[hasErrorName ? 'contact-us-input-err' : 'contact-us-input']" placeholder="Nombre"/>
          <input v-model="email" :class="[hasErrorEmail ? 'contact-us-input-err' : 'contact-us-input']" placeholder="Email"/>
          <textarea v-model="message" :class="[hasErrorMessage ? 'contact-us-text-area-err' : 'contact-us-text-area']" placeholder="Mensaje"></textarea>
          <button v-if="alreadySent === false" @click="enviarConsulta()" class="contact-us-submit">Enviar</button>
          <button v-else class="contact-us-submit">Consulta Enviada</button>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="footer-wrapper">
      <div style="display: flex; gap: 8px;">
        <!-- <i class="fa fa-envelope" style="font-size:36px; color: white"></i> -->
        <a href="mailto:daianamarielgabriele@hotmail.com" class="fa fa-envelope" style="font-size:36px; color: white"></a>
        <a @click="openIg()" class="fa fa-instagram" style="font-size:36px; color: white; cursor: pointer"></a>
        <a @click="openLinkedIn()" class="fa fa-linkedin" style="font-size:36px; color: white; cursor: pointer"></a>
        <a @click="openWp()" class="fa fa-whatsapp" style="font-size:36px;color: white; cursor: pointer"></a>
      </div>
    </div>
  </div>
</template>

<script>
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});const f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!=='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WGCC55QT');

import {postConsultation, postVisitorInfo} from "@/api/feed";

export default {
  methods: {
    scrollToAbout () {
       document.getElementById('myBioImage').scrollIntoView({behavior: "smooth"});
    },
    async enviarConsulta() {
      let hasErrors = false

      if (this.email.length === 0) {
        this.hasErrorEmail = true

        hasErrors = true
      }

      if (this.name.length === 0) {
        this.hasErrorName = true

        hasErrors = true
      }

      if (this.message.length === 0) {
        this.hasErrorMessage = true

        hasErrors = true
      }

      if (hasErrors === true) {
        return
      }

      const payload = {
        email: this.email,
        name: this.name,
        message: this.message
      }

      const { status } = await postConsultation(payload)

      if (status === 201) {
        this.alreadySent = true
        this.hasErrorEmail = false
        this.hasErrorName = false
        this.hasErrorMessage = false
      } else {
        console.log('err')
      }
    },
    openWp () {
      window.open('https://api.whatsapp.com/send?phone=5491168776345')
    },
    openIg () {
      window.open('https://instagram.com/psico_daiagabriele')
    },
    openLinkedIn () {
      window.open('https://www.linkedin.com/in/daiana-mariel-gabriele-1ab871161/')
    }
  },
  data() {
    return {
      email: '',
      name: '',
      message: '',
      alreadySent: false,
      hasErrorEmail: false,
      hasErrorName: false,
      hasErrorMessage: false
    }
  },
  beforeCreate() {
    document.body.style.margin = '0px';
    document.body.style.padding = '0px';
  },
  async created() {
    console.log('created')

    await postVisitorInfo()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  html {
    scroll-behavior: smooth;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .about-content-display {
    display: flex;
    justify-content: center;
    gap: 30px;
  }
  .about-content-firt-block {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    width: 400px;
    height: auto;
    padding: 50px 30px 50px 40px;
  }
  .about-content-first-block-title {
    font-size: 3.75rem;
    letter-spacing: normal;
    text-transform: none;
    font-family: 'serif';
    font-weight: 700;
    margin-bottom: 0px;
  }
  .about-content-firt-block-desc {
    font-size: 1.125rem;
    line-height: 2;
  }
  .about-content-firt-block-button {
    border-width: 0px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 1rem;
    border-style: solid;
    background-color: #d7afaf;
    cursor: pointer;
    width: 205px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-content-second-block {
    /* background-color: red; */
    width: 600px;
    height: 600px;
    position: relative;
    z-index: -1;
  }
  .second-block-image-block {
    position: absolute;
    width: 100px;
    height: 100px;
  }
  .second-block-image-block_one {
    background-color: #e1d5d8;
    height: 541px;
    width: 412px;
  }
  .second-block-image-block_two {
    background-color: #d7afaf;
    right: 0;
    top: 70px;
    width: 505px;
    height: 426px;
  }
  .second-block-main-image {
    width: 521px;
    height: 539px;
    position: absolute;
    background-color: lightcoral;
    margin: auto;
    left: 36px;
    bottom: 20px;
  }

  .treatments-block {
    height: 1250px;
    background-color: #d7afaf;
    padding-top: 25px;
  }
  .treatments-block-cards-wrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .treatments-block-card {
    background-color: red;
    width: 33.33%;
    height: 150px;
  }
  .cards-wrapper {
    display: flex;
    justify-content: center;
  }
  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr 1fr;
    grid-gap: 30px;
    min-height: 604px;
  }
  .card {
    /* border: 2px solid #e7e7e7; */
    /* border-radius: 4px; */
    /* padding: .5rem; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #ffffff;
    padding: 5px;
  }
  .card-title {
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: 700;
    font-family: 'serif';
  }
  .card-description {
    font-style: italic;
    font-size: 1.125rem;
    line-height: 2;
    color: #999999 !important;
    padding: 4px;
  }
  .bio-wrapper {
    min-height: 600px;
    background-color: #ffffff;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .bio-image-container {
    background-color: #ffffff;
    width: 258px;
    height: 350px;
    margin-top: -60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bio-image {
    /* background-color: lightcoral; */
    width: 250px;
    height: 342px;
  }
  .bio-text {
    max-width: 1100px;
    font-size: 1.125rem;
    line-height: 2;

    font-family: 'serif';
    /* text-align: center; */
    color: #2c3e50;
  }
  .contact-us-wrapper {
    height: 800px;
    background-color: #d7afaf;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact-us-wrapper-contact-us {
    background-color: #ffffff;
    width: 710px;
    height: 582px;
    justify-content: center;
    align-items: start;
    display: flex;
    margin-bottom: 45px;
  }
  .contact-us-wrapper-personal-info {
    background-color: #e1d5d8;
    width: 485px;
    height: 485px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    z-index: 5;
    margin-right: -50px;
    margin-top: 160px;
  }
  .contact-us-personal-info-title {
    font-size: 1.25rem;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-family: 'serif';
    font-weight: 400;
  }
  .contact-us-info-description {
    color: #292e33;
    font-size: 1.125rem;
    line-height: 2;
    font-family: 'serif';
    font-style: italic;
  }
  .contact-us-title {
    font-weight: 700;
    font-size: 3rem;
    line-height: 1.1;
    font-family: 'serif';
  }
  .contact-us-input {
    border-width: 2px;
    border-left-style: none;
    border-right-style: none;
    border-top-style: none;
    border-color: #000000;
    stroke: #000000;
    width: 460px;
    height: 48px;
    border-bottom-style: groove;
  }
  .contact-us-input-err {
    border-width: 2px;
    border-left-style: none;
    border-right-style: none;
    border-top-style: none;
    border-color: red;
    stroke: #000000;
    width: 460px;
    height: 48px;
    border-bottom-style: groove;
  }
  .contact-us-input:focus {
    outline: none;
  }
  .contact-us-text-area {
    border-width: 2px;
    border-left-style: none;
    border-right-style: none;
    border-top-style: none;
    border-color: #000000;
    stroke: #000000;
    width: 460px;
    height: 124px;
    border-bottom-style: groove;
    outline: none;
  }
  .contact-us-text-area-err {
    border-width: 2px;
    border-left-style: none;
    border-right-style: none;
    border-top-style: none;
    border-color: red;
    stroke: #000000;
    width: 460px;
    height: 124px;
    border-bottom-style: groove;
    outline: none;
  }
  .contact-us-form {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .contact-us-submit {
    height: 56px;
    letter-spacing: 2px;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1rem;
    color: #ffffff;
    background-color: #d7afaf;
    border-style: hidden;
    cursor: pointer;
  }
  .footer-wrapper {
    height: 200px;
    background-color: #333333;
    display: flex;
    gap: 2px;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 600px) {
    .about-content-first-block-title {
      font-size: 2.75rem;
    }

    .about-content-display {
      flex-direction: column;
    }
    .about-content-firt-block {
      width: auto;
    }
    .about-content-second-block {
      width: auto;
      height: 325px;
    }
    .treatments-block {
      height: fit-content;
    }
    .cards {
      display: flex;
      flex-direction: column;
      gap: 10px
    }
    .card {
      margin: 10px;
    }
    .contact-us-wrapper {
      flex-direction: column;
      height: fit-content;
    }
    .second-block-image-block_one {
      height: 265px;
      width: 255px;
      margin-left: 5px;
    }
    .second-block-image-block_two {
      width: 213px;
      height: 167px;
      margin-right: 5px;
    }
    .second-block-main-image
    {
      height: 255px;
      width: 280px;
      bottom: auto;
      top: 40px;
      left: 34px;
    }
    .bio-image-container {
      margin-top: 0;
    }
    .bio-text {
      padding: 10px;
    }
    .contact-us-wrapper-personal-info {
      margin-right: 10px;
      margin-left: 10px;
      margin-top: 40px;
      width: auto;
    }
    .contact-us-input {
      width: 300px;
    }
    .contact-us-input-err {
      width: 300px;
    }
    .contact-us-form {
      align-items: center;
      display: flex;
    }
    .contact-us-text-area {
      width: 300px;
    }
    .contact-us-text-area-err {
      width: 300px;
    }
    .contact-us-wrapper-contact-us {
      width: 346px;
    }
  }
</style>

