import { post, get, failedRequest } from '@/config/api'

const postConsultation = async (payload) => {
    try {
        const { status, data } = await post('/api/consultations', {
            email: payload.email,
            name: payload.name,
            message: payload.message
        })

        return { status, data }
    } catch (e) {
        return failedRequest(e)
    }
}

const postVisitorInfo = async () => {
    try {
        const { status, data } = await post('/api/visitors')

        return { status, data }
    } catch (e) {
        return failedRequest(e)
    }
}

const getConsultations = async () => {
    try {
        const { status, data } = await get('/api/consultations')

        return { status, data }
    } catch (e) {
        return failedRequest(e)
    }
}

export {
    postConsultation,
    postVisitorInfo,
    getConsultations
}
